/* eslint-disable indent */
import Vue from 'vue'
import api from '@/app/quadro-implantacao/shared/services/api'

const state = {
  cartaoCapa: null,
  cartao: null,
  historicos: null,
}

const getters = {
  cartaoCapa: state_ => state_.cartaoCapa,
  cartao: state_ => state_.cartao,
  historicos: state_ => state_.historicos,

}

const actions = {
  statusInicialCartao({ commit }) {
    const cartao = {
        titulo: '',
        descricao: '',
        autorId: null,
        colunaId: null,
        quadroId: null,
        autor: {
          id: null,
          nome: '',
        },
        membros: [],
        etiquetas: [],
        anexos: [],
        grupoAtividades: [],
        compromisso: null,
      }
      commit('SET_CARTAO', cartao)
 },
 async getCartaoCapa({ commit }, id) {
   await api.getCartaoCapaById(id)
    .then(payload => {
      commit('SET_CARTAO_CAPA', payload.data)
    })
    .catch(err => {
      throw err
    })
 },

 async getCartaoBy({ commit }, id) {
    if (id && id > 0) {
      await api.getCartaoById(id)
        .then(payload => {
          const cartao = payload.data
          this.idQuadro = payload.data.quadroId
          if (cartao.membros && cartao.membros.length > 0) {
            // eslint-disable-next-line no-shadow
            cartao.membros.map(({ id, membroId, nome }) => ({ id: membroId, membroId: id, nome }))
          }

          if (cartao.anexos && cartao.anexos.length > 0) {
            cartao.anexos.map(({ nomeDocumento }) => ({ nomeDocumento, name: nomeDocumento, documento: '' }))
          }

          commit('SET_CARTAO', cartao)
          return state.cartao
        })
        .catch(err => Promise.reject(err))
    }
  },

  copiarCartao({ dispatch }, cartao) {
    api.duplicarCartao(cartao)
        .then(payload => {
          dispatch('coluna/adicionarCartaoDaColuna', payload.data, { root: true })
        })
        .catch(err => {
          throw err
        })
  },

  // eslint-disable-next-line no-unused-vars
  deletarCartao({ commit }, id) {
    api.deletarCartao(id).then(() => {
      this.$sucesso('Cartão Removido com sucesso')
    })
      .catch(err => {
        throw err
      })
  },

  updateTituloCartao({ commit }, { id, titulo }) {
    if (titulo) {
      api.alterarTitulo(id, titulo)
        .then(() => {
          commit('SET_TITULO', titulo)
        })
        .catch(err => {
          throw err
        })
    } else {
      throw new Error('Não é possivel alterar o titulo e deixar vazio')
    }
  },

  // eslint-disable-next-line no-unused-vars
  async updateStatusAtividade({ commit, dispatch }, { indexGrupoAtividade, indexAtividade, atividadeConcluida }) {
    const { cartao } = state
    cartao.grupoAtividades[indexGrupoAtividade].atividades[indexAtividade].concluida = atividadeConcluida
    const atividade = cartao.grupoAtividades[indexGrupoAtividade].atividades[indexAtividade]
    await api.checkAtividade(atividade.id, atividadeConcluida).then(() => {
      dispatch('updatePercentualConcluidas', indexGrupoAtividade)
      commit('SET_CARTAO', cartao)
      return cartao
    })
      .catch(err => {
        Promise.reject(err)
      })
  },

  updatePercentualConcluidas({ commit }, indexGrupoAtividade) {
    const { cartao } = state
    const totalAtividades = cartao.grupoAtividades[indexGrupoAtividade].atividades.length
    const conlcuidas = cartao.grupoAtividades[indexGrupoAtividade].atividades.filter(item => item.concluida).length
    const percentual = parseFloat(((conlcuidas * 100) / totalAtividades).toFixed(2))
    commit('SET_PERCETUAL', { percentual, indexGrupoAtividade })
  },

  async getAnexos({ commit }, id) {
    this.isLoading = false
    await api.getAnexos(id)
      .then(paylod => {
        if (paylod.data && paylod.data.length > 0) {
          commit('SET_ANEXOS_RESPONSE', paylod.data)
        }
        return state.cartao.anexos
      })
      .catch(err => Promise.reject(err))
  },

  async getHistoricos({ commit }, id) {
    await api.getHistoricoCartao(id).then(payload => {
      if (payload.data != null) {
        commit('SET_HISTORICOS', payload.data)
      }
    })
    .catch(err => {
      throw err
    })
  },

  getUltimoHistorico({ commit }, id) {
    api.getUltimoHistoricoCartao(id).then(payload => {
      if (payload.data != null) {
        const { historicos } = state
        if (historicos && historicos.length > 0) {
          historicos.unshift(payload.data)
          commit('SET_HISTORICOS', historicos)
        }
      }
    })
      .catch(err => {
        throw err
      })
  },

  addGrupoAtividades({ commit }, grupo) {
    api.atualizarGrupoAtividades(grupo).then(payload => {
      const { cartao } = state
      cartao.grupoAtividades.push(payload.data)
      commit('SET_CARTAO', cartao)
    })
    .catch(err => {
      throw err
    })
  },

  deletarGrupoAtividades({ commit }, { indexGrupo, grupoId }) {
    const { cartao } = state
    api.deletarGrupoAtividade(grupoId).then(() => {
      cartao.grupoAtividades.splice(indexGrupo, 1)
      commit('SET_CARTAO', cartao)
    })
    .catch(err => {
      throw err
    })
  },

  addAtividades({ commit }, { atividade, indexGrupo }) {
    const { cartao } = state

    api.atualizarAtividades(atividade).then(payload => {
      cartao.grupoAtividades[indexGrupo].atividades.push(payload.data)
      commit('SET_CARTAO', cartao)
    }).catch(err => {
      throw err
    })
  },

  deletarAtividade({ commit }, { indexGrupoAtividade, indexAtividade }) {
    const { cartao } = state
    const atividadeId = cartao.grupoAtividades[indexGrupoAtividade].atividades[indexAtividade].id
    api.deletarAtividade(atividadeId).then(() => {
      cartao.grupoAtividades[indexGrupoAtividade].atividades.splice(indexAtividade, 1)
      commit('SET_CARTAO', cartao)
    })
      .catch(err => {
        throw err
      })
  },

  setStatusAtividade({ commit }, { indexGrupoAtividade, indexAtividade, atividadeConcluida }) {
    const { cartao } = state
    cartao.grupoAtividades[indexGrupoAtividade].atividades[indexAtividade].concluida = atividadeConcluida
      const atividade = cartao.grupoAtividades[indexGrupoAtividade].atividades[indexAtividade]
      api.checkAtividade(atividade.id, atividadeConcluida).then(() => {
        commit('SET_CARTAO', cartao)
      })
      .catch(err => {
        throw err
      })
  },

  setMembrosResponse({ commit }, membros) {
      const novosMembros = membros.map(({ id, membroId, nome }) => ({ id: membroId, membroId: id, nome }))
      commit('SET_MEMBROS_RESPONSE', novosMembros)
  },

  setAnexoResponse({ commit }, anexos) {
    const novosAnexos = anexos.map(({ nomeDocumento }) => ({ nomeDocumento, name: nomeDocumento, documento: '' }))
    commit('SET_ANEXOS_RESPONSE', novosAnexos)
  },

  updateMembros({ commit }, { id, membros }) {
    api.atualizarMembrosCartao({
      id,
      membros,
    }).then(payload => {
      // eslint-disable-next-line no-shadow
      const newMembros = payload.data.map(({ id, membroId, nome }) => ({ id: membroId, membroId: id, nome }))
      commit('SET_MEMBROS', newMembros)
    })
    .catch(err => {
      throw err
    })
  },

  updateDataEntrega({ commit }, cartao) {
    let newData = ''
    if (cartao.dataEntrega) {
      const partesData = cartao.dataEntrega.split('-')
      const dia = partesData[2]
      const mes = partesData[1]
      const ano = partesData[0]
      newData = `${dia}/${mes}/${ano}`
    }
    // eslint-disable-next-line no-unused-vars
    api.atualizarDataEntregaCartao(cartao.id, newData).then(() => {
      commit('SET_CARTAO', cartao)
      commit('SET_DATA_ENTREGA', newData)
    })
      .catch(err => {
        throw err
      })
  },

  updateDescricao({ commit }, descricao) {
    api.atualizarDescricaoCartao(descricao).then(() => {
      commit('SET_DESCRICAO', descricao)
    })
    .catch(err => {
      throw err
    })
  },

}

const mutations = {
  SET_CARTAO(state_, cartao) {
    Vue.set(state_, 'cartao', cartao)
  },

  SET_CARTAO_CAPA(state_, cartao) {
    Vue.set(state_, 'cartaoCapa', cartao)
  },

  SET_MEMBROS(state_, membros) {
    Vue.set(state_.cartao, 'membros', membros)
  },

  SET_MEMBROS_REQUEST(state_, membros) {
    Vue.set(state_.cartao, 'membros', membros)
  },

  SET_ANEXOS_RESPONSE(state_, anexos) {
    Vue.set(state_.cartao, 'anexos', anexos)
  },

  SET_HISTORICOS(state_, historicos) {
    Vue.set(state_, 'historicos', historicos)
  },

  SET_TITULO(state_, titulo) {
    Vue.set(state_.cartao, 'titulo', titulo)
  },

  SET_PERCETUAL(state_, { percentual, indexGrupoAtividade }) {
    Vue.set(state_.cartao.grupoAtividades[indexGrupoAtividade], 'totalConcluidas', percentual)
  },

  SET_DATA_ENTREGA(state_, data) {
    Vue.set(state_.cartao, 'dataEntregaText', data)
  },

  SET_DESCRICAO(state_, descricao) {
    Vue.set(state_.cartao, 'descricao', descricao)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
